import React from 'react'
import Layout from '../../components/layout';
import Helmet from "react-helmet"
import SearchComponent from "../../components/search/searchredemption"

const Home = () => {
    return (
        <Layout includeSearchBar={false}>
            <Helmet>
                <title>Vantage Redemption</title>

                <meta name="description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <meta property="twitter:title" content="Vantage Circle" />
                        
                <meta property="twitter:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                        
                <meta property="og:type" content="website"/>
                        
                <meta property="og:url" content="https://docs.vantagecircle.com/vantage-redemption/"/>

                <meta property="og:site_name" content="Vantage Circle"/>
                <meta property="og:title" content="Vantage Circle" />

                <meta property="og:description" content="Join our team as we redefine the rewards and recognition infrastructure for thousands of businesses globally. Check out available openings." />
                <link rel="canonical" href="https://docs.vantagecircle.com/vantage-redemption/"></link>
            </Helmet>
            <SearchComponent />
            <section className="container max-w-6xl mx-auto py-10 md:py-10">
                <div className="mx-auto px-6 mb-10">
                    <div className="text-center">
                        <h2 className="section-title"><span className="text-orange">Browse</span> Resources by Role</h2>
                    </div>
                </div>
                <div className="max-w-3xl mx-auto grid grid-cols-1 gap-5 md:gap-6 sm:grid-cols-1 md:grid-cols-2 items-center px-6">
                    <div className="h-full card-1 w-full rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="bg-white p-8">
                            <div className="relative mb-10">
                                <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1641463704/gatsbycms/uploads/2022/01/Hradmin.png" alt="HR Administrators" />
                            </div>
                            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                                <div className="">
                                    <a href="/vantage-redemption/hr-administrators/" className="vc-colored-btn">HR Administrators</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-full card-1 w-full rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out">
                        <div className="bg-white p-8">
                            <div className="relative mb-10">
                                <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1641463704/gatsbycms/uploads/2022/01/Employees.png" alt="Employees" />
                            </div>
                            <div className="mt-5 sm:mt-8 md:mt-10 flex justify-center">
                                <div className="">
                                    <a href="/vantage-redemption/employees/" className="vc-colored-btn">Employees</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container max-w-4xl vc-doc-content px-12 pb-20">
                <h2>Overview</h2>
                <p>The following documentation is a step by step guide on understanding how to use the key features of the Vantage Redemption Module. The manual will be updated continuously with the sole purpose of helping users with new features and product enhancements.</p>
                <h2>What is Vantage Redemption?</h2>
                <p>Vantage Redemption is a powerful and versatile rewards redemption platform that lets you redeem reward points for gift cards, merchandise, and employee travel experiences.</p>
                <h2>Benefits Of Having A Points-Redemption Platform</h2>
                <p>Having a points-based rewards system allows employers to show appreciation for deserving employees by offering them reward points. But say, you have accumulated points for exemplary performance at work.</p>
                <h2>What are you going to do with the points?</h2>
                <p>That's where the role of a dedicated points-redemption platform comes into play.</p>
                <p>Vantage Redemption empowers employees to select the reward of their choosing. They can redeem points for your favorite merchandise, exciting experiences, and gift cards.</p>
                <p><strong>Advantages of including points based rewards in your rewards and recognition strategy include:</strong></p>
                <ul className="list-inside orangebullets">
                    <li>Higher Engagement</li>
                    <li>Easy To Recognize Global Workforce</li>
                    <li>Flexible In Nature</li>
                    <li>Easy To Understand By Employees</li>
                    <li>Enables Frequent Recognition</li>
                    <li>Measure Impact And Track Metrics</li>
                    <li>Points Add Value To Recognition</li>
                </ul>
                <h2>How Does It Work?</h2>
                <p>When an employee gets rewarded with reward points, the points will be stored in an online wallet. They can use these points to choose from merchandise, experiences, and gift cards.</p>
                <h3>Merchandise</h3>
                <p>Employees can redeem reward points to buy a wide range of products from top global brands. Product types in merchandise stores can include apparel, footwear, home decor, electronics, accessories, sports equipment, and many more.</p>
                <h3>Experiences</h3>
                <p>Employees can also use their reward points for booking adventure trips, events, and experiences. Vantage Circle has teamed up with popular vendors that offer travel packages to make reward points eligible for booking.</p>
                <h3>Gift cards</h3>
                <p>A gift card is one of the most popular methods of employee recognition. It is like a prepaid debit card of a specific value that you can use for a variety of purchases. Its value is derived by redeeming reward points. Vantage Redemption offers a Global Catalog with a wide range of gift cards from top brands and retailers.</p>
            </section>
        </Layout>
    )
}

export default Home